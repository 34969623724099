import React from 'react';
import { useTranslation } from 'react-i18next';

export const ChecksumColumns = () => {
    const { t } = useTranslation();

    return [
        {
            title: t('gameId'),
            label: 'gameId',
            dataIndex: 'fileId',
            key: 'fileId',
            width: 135,
            fixed: true,
        },
        {
            title: t('fileName'),
            label: 'fileName',
            dataIndex: 'fileNameVersion',
            key: 'fileNameVersion',
        },
        {
            title: t('status'),
            label: 'status',
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => a.success === b.success ? 0 : a.success ? -1 : 1,
            dataIndex: 'success',
            key: 'success',
            width: 165,
            render: (text: any) => (
                <span className={`account-status${text ? '' : ' blocked'}`}>
                    {t(text ? 'success' : 'failed')}
                </span>
            ),
        },
    ];
};
