import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import React from "react";

interface IProps {
    roundUuids: string[] | null;
    retryAllModalVisible: boolean;
    retrySessionBatch: Function;
    setRetryAllModalVisible: Function;
    totalNumberOfElements: number | null;
}

export const RetryAllModal = (props: IProps) => {
    const { t } = useTranslation();
    const { roundUuids, retryAllModalVisible, retrySessionBatch, setRetryAllModalVisible, totalNumberOfElements } = props;

    return <Modal
                open={retryAllModalVisible}
                title={'Retry All Sessions'}
                onOk={async () => {
                    if (roundUuids && roundUuids.length > 0)
                        retrySessionBatch({roundUuids});

                    setRetryAllModalVisible(false);
                }}
                onCancel={() => setRetryAllModalVisible(false)}
                okText={t('Retry all')}
                cancelText={t('Cancel')}
            >
                {totalNumberOfElements !== null ? (
                    <p>
                        {'Are you sure you want to retry ' +
                            totalNumberOfElements +
                            ' sessions?'}
                    </p>
                ) : (
                    <p>{'Loading total number of elements'}</p>
                )}
            </Modal>
};
