import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import {
    addGameDefaultConfigSuccess,
    addGameSuccess,
    GamesTypes,
    getDefaultGameConfigCurrencyCountSuccess,
    getDefaultGameConfigSuccess,
    updateDefaultGameConfigSuccess,
} from '../../actions/configProvider/games-actions';
import { IAction } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const addNewGameEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GamesTypes.ADD_GAME_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            const { id, name, wager, gameConfig, freeRoundsSupported, buyFeatureSupported } =
                action.payload;
            const payloadForGame = {
                id,
                name,
                wager,
                freeRoundsSupported,
                buyFeatureSupported,
            };
            const payloadForGameConfig = {
                gameId: id,
                gameConfig,
            };

            return from(
                axios.post(`${SERVER_URL}/configuration/game`, payloadForGame, { headers }),
            ).pipe(
                mergeMap(() =>
                    of(addGameSuccess(action.payload), {
                        type: GamesTypes.ADD_GAME_DEFAULT_CONFIG_REQUEST,
                        payload: payloadForGameConfig,
                    }),
                ),
                catchError((error) => of(apiError(error, GamesTypes.ADD_GAME_REQUEST))),
            );
        }),
    );

export const addNewGameDefaultConfigEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GamesTypes.ADD_GAME_DEFAULT_CONFIG_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            const objToSend = action.payload;

            return from(
                axios.post(`${SERVER_URL}/configuration/default-game-configuration`, objToSend, {
                    headers,
                }),
            ).pipe(
                map((response) => addGameDefaultConfigSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, GamesTypes.ADD_GAME_DEFAULT_CONFIG_REQUEST)),
                ),
            );
        }),
    );

export const getGameDefaultConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GamesTypes.GET_DEFAULT_GAME_CONFIGURATIONS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            const gameIds = action.payload || [];

            return from(
                axios.get(
                    `${SERVER_URL}/configuration/default-game-configuration?gameIds=${gameIds}`,
                    {
                        headers,
                    },
                ),
            ).pipe(
                map((response) => getDefaultGameConfigSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, GamesTypes.GET_DEFAULT_GAME_CONFIGURATIONS_REQUEST)),
                ),
            );
        }),
    );

export const getGameDefaultConfigurationsCurrencyCountEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GamesTypes.GET_DEFAULT_GAME_CONFIGURATIONS_CURRENCY_COUNT_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            const gameIds = action.payload || [];

            return from(
                axios.get(
                    `${SERVER_URL}/configuration/default-game-configuration/currency-count?gameIds=${gameIds}`,
                    {
                        headers,
                    },
                ),
            ).pipe(
                map((response) => getDefaultGameConfigCurrencyCountSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, GamesTypes.GET_DEFAULT_GAME_CONFIGURATIONS_CURRENCY_COUNT_REQUEST)),
                ),
            );
        }),
    );

export const updateGameDefaultConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GamesTypes.UPDATE_DEFAULT_GAME_CONFIGURATION_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            const objToSend = action.payload;

            return from(
                axios.put(`${SERVER_URL}/configuration/default-game-configuration`, objToSend, {
                    headers,
                }),
            ).pipe(
                map((response) => updateDefaultGameConfigSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, GamesTypes.UPDATE_DEFAULT_GAME_CONFIGURATION_REQUEST)),
                ),
            );
        }),
    );
