import {useTranslation} from "react-i18next";
import {Modal} from "antd";

interface IProps {
    sessionIds: number[] | null;
    resolveAllModalVisible: boolean;
    resolveSessionBatch: Function;
    setResolveAllModalVisible: Function;
    totalNumberOfElements: number | null;
}

export const ResolveAllSessionsModal = (props: IProps) => {
    const { t } = useTranslation();
    const { sessionIds, resolveAllModalVisible, resolveSessionBatch, setResolveAllModalVisible, totalNumberOfElements } = props;

    return <Modal
        open={resolveAllModalVisible}
        title={'Resolve All Sessions'}
        onOk={async () => {
            if (sessionIds && sessionIds.length > 0)
                resolveSessionBatch({sessionIds});
            setResolveAllModalVisible(false);
        }}
        onCancel={() => setResolveAllModalVisible(false)}
        okText={t('Resolve all')}
        cancelText={t('Cancel')}
    >
        {totalNumberOfElements !== null ? (
            <p>
                {'Are you sure you want to resolve ' +
                    totalNumberOfElements +
                    ' sessions?'}
            </p>
        ) : (
            <p>{'Loading total number of elements'}</p>
        )}
    </Modal>
};
