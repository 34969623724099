import {useTranslation} from "react-i18next";
import {Checkbox, Divider, Modal} from "antd";
import React from "react";
import {ITableData} from "../Reports/columnsData";
import { brokenRoundsAllColumns } from '../Reports/columnsData';

interface IProps {
    brokenRoundsColumns: ITableData[];
    tableToolsModalVisible: boolean;
    setBrokenRoundsColumns: Function;
    setTableToolsModalVisible: Function;
}

export const TableTools = (props: IProps) => {
    const { t } = useTranslation();
    const { tableToolsModalVisible, setTableToolsModalVisible, brokenRoundsColumns, setBrokenRoundsColumns } = props;
    const options = Object.entries(brokenRoundsAllColumns).map(field => field[1])
        .map((column) => ({
        label: t(column.title),
        value: column.key,
    }));

    return <Modal
        open={tableToolsModalVisible}
        title={'Table tools'}
        okText={t('table_tools_apply')}
        footer={null}
        onCancel={() => setTableToolsModalVisible(false)}
    >
        <div>
            <Divider>Columns displayed</Divider>
            <Checkbox.Group className="column-group"
                options={options}
                value={brokenRoundsColumns.map((c: ITableData) => c.key)}
                onChange={(checkedValues) => {
                    const checkedList = checkedValues.map(String);
                    setBrokenRoundsColumns(
                        Object.entries(brokenRoundsAllColumns).map(field => field[1])
                            .filter((column: any) => checkedList.includes(column.key)) );
                }}
            />
        </div>
    </Modal>


};