import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import React from "react";

interface IProps {
    roundUuids: string[] | null;
    onHoldAllModalVisible: boolean;
    onHoldRoundsBatch: Function;
    setOnHoldAllModalVisible: Function;
    totalNumberOfElements: number | null;
}

export const OnHoldAllModal = (props: IProps) => {
    const { t } = useTranslation();
    const { roundUuids, onHoldAllModalVisible, onHoldRoundsBatch, setOnHoldAllModalVisible, totalNumberOfElements } = props;

    return <Modal
        open={onHoldAllModalVisible}
        title={'Put on hold all broken rounds'}
        onOk={async () => {
            if (roundUuids && roundUuids.length > 0)
                onHoldRoundsBatch({roundUuids});

            setOnHoldAllModalVisible(false);
        }}
        onCancel={() => setOnHoldAllModalVisible(false)}
        okText={t('Put on hold all')}
        cancelText={t('Cancel')}
    >
        {totalNumberOfElements !== null ? (
            <p>
                {'Are you sure you want to put on hold ' +
                    totalNumberOfElements +
                    ' broken rounds?'}
            </p>
        ) : (
            <p>{'Loading total number of elements'}</p>
        )}
    </Modal>
};