import * as React from 'react';
import { connect } from 'react-redux';
import '../../commonCPStyles.scss';
import { withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import {Button, Empty} from 'antd';
import { ICurrencyData } from 'helpers/interfaces';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { PlusCircleOutlined } from '@ant-design/icons/lib';
import { Link } from 'react-router-dom';
import {getGameConfiguredCurrenciesRequest, sortAllCurrencies} from 'redux/actions/configProvider/currencies-actions';
import {
    getConfiguredCurrencies, getCurrencies,
    getSortedCurrencies,
    isAddingDefaultCurrencyFormLoading,
} from 'redux/selectors/configProvider/currency-selectors';
import {useEffect, useMemo} from 'react';
import { RootState } from '../../../redux/store';
import { currenciesData } from "../../../Containers/Reports/columnsData";
import CustomTable from "../../../Components/Table/CustomTable";
import {Loader} from "../../../Components/Loader/Loader";

interface IProps {
    currencies: ICurrencyData[];
    sortedCurrencies: ICurrencyData[];
    getConfiguredCurrencies: () => void;
    loading: boolean;
    sortHandler: Function;
    t: (key: string) => string;
}

const Currencies: React.FC<IProps> = ({currencies, sortedCurrencies, getConfiguredCurrencies, t, sortHandler }) => {
    useEffect(() => {
        getConfiguredCurrencies();
    }, [getConfiguredCurrencies]);

    const getCurrencies = useMemo(
        () =>
            currencies
                ? currencies.map((currencyData) => ({
                      ...currencyData,
                      key: currencyData.id,
                  }))
                : [],
        [currencies],
    );

    const rows =
        sortedCurrencies && sortedCurrencies.length
            ? sortedCurrencies
            : getCurrencies
                ? getCurrencies.length
                    ? getCurrencies
                    : []
                : null;
    return (
        <ContentComponent
            header={
                <div className="header-line cp-header-line">
                    <HeaderTitleComponent
                        title={t('currencies')}
                        customBreadcrumbs={<div>{t('all')}</div>}
                    />
                    <div className="cp-header-buttons">
                        <div className="cp-header-buttons__button">
                            <Link
                                id="cp-header-button__wrapper"
                                to={`/config-provider/currencies/add-default-config`}
                            >
                                <Button shape="round" danger>
                                    <PlusCircleOutlined /> {t('add_default_config')}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
            innerContent={
                rows ? (
                    rows.length ? (
                        <CustomTable
                            reportKey={'currenciesData'}
                            dataSource={rows}
                            columns={currenciesData}
                            sortHandler={sortHandler}
                            withoutCollapsing
                        />
                    ) : (
                        <Empty description={t('no_data')} />
                    )
                ) : (
                    <Loader />
                )
            }
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    currencies: getCurrencies(state),
    sortedCurrencies: getSortedCurrencies(state),
    configuredCurrencies: getConfiguredCurrencies(state),
    loading: isAddingDefaultCurrencyFormLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getConfiguredCurrencies: () => dispatch(getGameConfiguredCurrenciesRequest()),
    sortHandler: (key: any, sortState: any) => dispatch(sortAllCurrencies({'keyToSort' : key, 'sortState' :sortState })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Currencies));
